@tailwind base;
@tailwind components;
@tailwind utilities;

.emoji-picker-react {
  @apply bg-white dark:bg-gray-800
  }

  .emoji-picker-react .emoji-search {
    @apply dark:bg-gray-800  dark:text-white dark:border-gray-700 dark:focus:border-sky-500
    }
  
    .emoji-picker-react .emoji-categories  {
      @apply dark:bg-gray-800;
      }

      .emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar {
        @apply dark:bg-gray-800 w-5
      }

      .emoji-picker-react .emoji-categories button  {
        @apply contrast-0
        }

      .emoji-picker-react .emoji-scroll-wrapper .emoji-group::before {
        @apply dark:bg-gray-800  dark:text-white
      }

  @layer base {
    body {
      @apply bg-white dark:bg-gray-900;
    }
  }

  .navbar {
    background-color: var(--navBg);
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: var(--navBg);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
    padding: 0;
  }
  
  .navbar-toggle {
    background-color: var(--navBg);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  span {
    margin-left: 16px;
  }

.filepond--root {
    max-height: 150px;
    height: 150px;
}
  